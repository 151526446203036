import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import {CSSTransition} from 'react-transition-group'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link, useNavigate} from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'
import {format} from "date-fns";
import useFetch from '../../../hooks/useFetch'
import Loader from '../../ui/loader/Loader'
import AchatsAPI from '../../../services/AchatsAPI'
import AchatsItem from './AchatsItem'
import AlertCustom from '../../ui/alert/AlertCustom'
import {CartContext} from '../../../contexts/CartContext'
import Count from '../../ui/page/Count'
import {ITEMS_PER_PAGE_ACHATS, SHOP_CLOSE_HOUR} from '../../../constants/Constants'
import PaginationCustom from '../../ui/pagination/PaginationCustom'
import Select from '../../ui/form/Select'

const Achats = () => {
  const {cartItems} = useContext(CartContext)
  const [sortType, setSortType] = useState('articlesExtranetOrdre')
  const [sortOrder, setSortOrder] = useState('asc')
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const navigate = useNavigate();

  const [{data: achats, totalItems, isLoading, isError}, fetchData] = useFetch(
    AchatsAPI.getAchats(sortType, sortOrder),
  )

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) {
      fetchData(AchatsAPI.getAchats(sortType, sortOrder, currentPage))
    }
  }, [sortType, sortOrder, currentPage]);

  useEffect(() => {
    if (format(new Date(), 'yyyy-MM-dd HH:mm:ss') >= SHOP_CLOSE_HOUR.start && format(new Date(), 'yyyy-MM-dd HH:mm:ss') <= SHOP_CLOSE_HOUR.end) {
      navigate('/tableau-de-bord');
    }
  }, []);

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  // Gestion du changement de filtre
  const handleSortType = (event) => {
    const {selectedIndex} = event.target.options
    setCurrentPage(1)
    setSortOrder('asc')
    setSortType(event.target.options[selectedIndex].getAttribute('id'))
    setReload(true)
  }

  // Gestion du changement de tri
  const handleSortOrder = () => {
    const sort = sortOrder === 'asc' ? 'desc' : 'asc'
    setCurrentPage(1)
    setSortOrder(sort)
    setReload(true)
  }

  return (
    <>
      <Col
        xs={12}
        className="achats d-flex flex-row flex-wrap justify-content-between mt-3 mb-4">
        <h1>Boutique Florajet</h1>
        <Link
          to="/panier"
          role="button"
          className="btn btn-primary d-flex align-items-center btn-rounded ff-bold">
          <FontAwesomeIcon
            icon="shopping-cart"
            color="white"
            className="mr-2"
          />
          <span>Mon panier</span>
          <Badge pill variant="light" className="ml-1">
            {cartItems.length}
          </Badge>
        </Link>
      </Col>

      <Col
        xs={12}
        className="filter d-flex flex-row flex-wrap align-items-end align-items-sm-center">
        <Select
          controlId="achatsSelect"
          label="Trier par"
          classNamesForm="mb-3 mr-3"
          defaultValue="Meilleures ventes"
          onChange={handleSortType}
          options={
            <>
              <option id="articlesExtranetOrdre">Meilleures ventes</option>
              <option id="articlesExtranetReference">Référence</option>
              <option id="articlesExtranetLibelle">Libellé</option>
              <option id="articlesExtranetDescription">Description</option>
              <option id="articlesExtranetStock">Produits en stock</option>
              <option id="articlesExtranetMaxi">Max</option>
            </>
          }
        />
        <FontAwesomeIcon
          onClick={() => handleSortOrder()}
          size="lg"
          icon={`sort${
            sortOrder === 'asc' ? '-amount-up-alt' : '-amount-down'
          }`}
          color="var(--grey-darker)"
          className="mr-3 ml-auto mb-3"
        />

        <Count totalItems={totalItems} name="article" classNames="mb-3" />
      </Col>

      {isLoading && <Loader />}

      <AlertCustom
        heading={isError.heading}
        variant={isError.variant}
        bodyIcon={isError.icon}
        bodyContent={isError.content}
        bodyContentBold={isError.message}
        bodyContentItalic={isError.detail}
        transition={!!isError}
      />

      {!isLoading && !isError && (
        <CSSTransition
          in={true}
          appear={true}
          timeout={500}
          unmountOnExit
          classNames="fade">
          <Col xs={12}>
            <Row>
              {achats.map((item, index) => (
                <AchatsItem key={index} article={item} />
              ))}
            </Row>
          </Col>
        </CSSTransition>
      )}
    </>
  )
}

export default Achats
