import PropTypes from 'prop-types'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import Image from 'react-bootstrap/Image'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getIn} from 'formik'
import addDays from 'date-fns/addDays'
import {format} from 'date-fns'
import {useLocation} from 'react-router-dom'
import {differenceInCalendarDays} from "date-fns/differenceInCalendarDays";
import Box from '../../ui/box/Box'
import CatalogueAPI from '../../../services/CatalogueAPI'
import AuthContext from '../../../contexts/AuthContext'
import Select from '../../ui/form/Select'
import useFetch from '../../../hooks/useFetch'
import CommandeAPI from '../../../services/CommandeAPI'
import Field from '../../ui/form/Field'
import Checkbox from '../../ui/form/Checkbox'
import ModalCustom from "../../ui/modal/ModalCustom";
import AlertCustom from "../../ui/alert/AlertCustom";


export function ProductForm({formik, colXs, colSm}) {
  const {user} = useContext(AuthContext)
  const location = useLocation()
  const [isLoadingReference, setIsLoadingReference] = useState(false)
  const [selectedReference, setSelectedReference] = useState([])
  const [optionsTypeRef, setOptionsTypeRef] = useState()
  const [isShowModalReference, setIsShowModalReference] = useState(false);
  const productsNoCheckAvailableDates = ['WB82727', 'WB67364', 'WB82712'];
  const [{data: floralChoices, isLoading: isLoadingFloralChoice}] = useFetch(
    CommandeAPI.getChoixFloraux(),
  )
  const [{data: occasions, isLoading: isLoadingOccasions}] = useFetch(
    CommandeAPI.getOccasions(),
  )
  const [productIsAvailable, setProductIsAvailable] = useState();
  const [comingSoon, setComingSoon] = useState(false);

  const isForeignCountry = useMemo(
    () =>
      formik.values.receiver.countryLabel !== 'FRANCE' ||
      formik.values.receiver.country !== 'F',
    [formik.values.receiver.country, formik.values.receiver.countryLabel],
  )

  const handleSearchReference = useCallback(
    (query) => {
      let minimalCharactersForSearch = 2

      if (query.toUpperCase().startsWith('WB')) {
        minimalCharactersForSearch = 5
      }

      if (query.length >= minimalCharactersForSearch) {
        setIsLoadingReference(true)

        CatalogueAPI.getProductBySearch(
          query,
          formik.values.receiver.countryId,
          user.CodeFl,
        ).then((result) => {
          if (result.data) {
            setOptionsTypeRef(result.data)
          }

          setIsLoadingReference(false)
        })
      }
    },
    [formik.values.receiver.countryId],
  )

  function resetProductInformations() {
    setProductIsAvailable(false);
    formik.setFieldValue('scompo.id', '');
    formik.setFieldValue('scompo.reference', '');
    formik.setFieldValue('scompo.description', '');
    formik.setFieldValue('scompo.category', '');
    formik.setFieldValue('scompo.categoryLabel', '');
    formik.setFieldValue('scompo.amountmini', 0);
    formik.setFieldValue('scompo.amountmax', '');
    formik.setFieldValue('scompo.vat', '');
    formik.setFieldValue('vat', '');
    formik.setFieldValue('scompo.label', '');
    formik.setFieldValue('scompo.hasAvailableDates', true)
  }

  function checkReferenceIsValid(value) {
    let isValid = false;

    if (optionsTypeRef && value !== '') {
      for (let i = 0; i < optionsTypeRef.length; i += 1) {
        if ((optionsTypeRef[i].reference === value || optionsTypeRef[i].libcompo === value) && (selectedReference.length > 0 && (selectedReference[0].reference === value || selectedReference[0].libcompo === value))) {
          isValid = true;
          break;
        }
      }

      if (!isValid) {
        setIsShowModalReference(true);
        setSelectedReference([{libcompo: ''}]);
        resetProductInformations();
      }
    }
  }

  async function onChangeReference(value) {
    setSelectedReference(value)

    const changedReference = formik.values.scompo && value.length > 0 && formik.values.scompo.reference !== value[0].reference;

    if (changedReference) {
      const newAmountVat =
          parseFloat(
              value[0].reference === ''
                  ? 0
                  : value[0].montantminie,
          ).toFixed(2);

      formik.setFieldValue('amountvat', newAmountVat);
    }

    if (value.length > 0) {
      formik.setFieldValue('scompo.id', value[0].idcompo)
      formik.setFieldValue('scompo.reference', value[0].reference)
      formik.setFieldValue('scompo.description', value[0].descriptionTech)
      formik.setFieldValue('scompo.category', value[0].categCode)
      formik.setFieldValue(
        'scompo.categoryLabel',
        value[0].categorie[0].categDesc,
      )
      formik.setFieldValue('scompo.amountmini', value[0].montantminie)
      formik.setFieldValue('scompo.amountmax', value[0].montante)
      formik.setFieldValue('scompo.vat', value[0].tauxtva)
      formik.setFieldValue('vat', value[0].tauxtva)
      formik.setFieldValue('scompo.label', value[0].libcompo)

      setProductIsAvailable(false)

      if (!productsNoCheckAvailableDates.includes(value[0].reference)) {
        await CatalogueAPI.getAvailableDatesByProduct(
            value[0].idcompo,
            formik.values.receiver.countryId,
        ).then((result) => {
          if (result.data) {
            if (Array.isArray(result.data) && result.data.length === 0) {
              setProductIsAvailable(false)
              formik.setFieldValue('scompo.hasAvailableDates', false)
            } else {
              const dateStart = new Date(result.data.start)
              const dateEnd = new Date(result.data.end)
              const now = new Date()
              formik.setFieldValue('delivery.dateStart', now)

              if (now < dateStart) {
                formik.setFieldValue('delivery.dateStart', dateStart)
              }

              formik.setFieldValue(
                  'delivery.dateEnd',
                  addDays(formik.values.delivery.dateStart, 73),
              )

              if (addDays(formik.values.delivery.dateStart, 73) > dateEnd
                  && format(addDays(dateEnd, 1), 'dd-MM') !== format(dateStart, 'dd-MM') // Permet de ne pas limiter au 31/12 si un produit est disponible toute l'année
              ) {
                formik.setFieldValue('delivery.dateEnd', dateEnd)
              }

              setComingSoon(false);
              setProductIsAvailable(now >= dateStart && now <= dateEnd);
              formik.setFieldValue('scompo.hasAvailableDates', now >= dateStart && now <= dateEnd);
              console.log(now >= dateStart && now <= dateEnd);
              if (!(now >= dateStart && now <= dateEnd)) {
                const differenceOfDays = differenceInCalendarDays(dateStart, now);
                setComingSoon(differenceOfDays > 0 && differenceOfDays <= 73);
                setProductIsAvailable(differenceOfDays > 0 && differenceOfDays <= 73);
                formik.setFieldValue('scompo.hasAvailableDates', differenceOfDays > 0 && differenceOfDays <= 73);
              }

              if (formik.values.delivery.date < dateStart) {
                formik.setFieldValue('delivery.date', null)
              }
            }
          }
        })
      }
    }
  }

  useEffect(() => {
    if (location.state && location.state.order) {
      CatalogueAPI.getProductBySearch(
        location.state.order.scompo.reference,
        formik.values.receiver.countryId,
        user.CodeFl,
      ).then((result) => {
        if (result.data && result.data.length > 0) {
          onChangeReference([result.data[0]])
        }
      })
    }
  }, [])

  return (
    <>
      <Box
        boxXs={colXs}
        boxSm={colSm}
        boxName={'product'}
        headerClassNames={'rounded-top'}
        header={true}
        headerTextColor={'white'}
        headerContent={<h3 className="my-auto">Choix floral</h3>}
        headerPadding={'p-3'}
        boxContentPadding={'p-3'}
        content={
          <>
            <Row>
              <Col xs={12} sm={9}>
                <Row>
                  <Col>
                    <Form.Group controlId={'scompo.reference'}>
                      <Form.Label>Référence</Form.Label>
                      <AsyncTypeahead
                        id={'scompo.reference'}
                        name={'scompo.reference'}
                        minLength={2}
                        maxResults={10}
                        filterBy={() => true}
                        promptText={'Entrez votre recherche'}
                        searchText={'Chargement...'}
                        emptyLabel={`${
                          isForeignCountry
                            ? 'Merci de choisir une référence internationale de votre catalogue'
                            : 'Aucun résultat'
                        }`}
                        paginationText={'Afficher plus de résultats'}
                        labelKey={(option) => `${option.libcompo}`}
                        placeholder={'Référence'}
                        isLoading={isLoadingReference}
                        selected={selectedReference}
                        options={optionsTypeRef}
                        onSearch={handleSearchReference}
                        useCache={false}
                        renderMenuItemChildren={(option) => (
                          <>
                            <span>{option.libcompo}</span>
                            <span> ({option.reference})</span>
                          </>
                        )}
                        onInputChange={(value) => {
                          formik.setFieldValue('scompo.reference', value)
                          formik.setFieldValue('delivery.dateStart', new Date())
                          formik.setFieldValue(
                            'delivery.dateEnd',
                            addDays(new Date(), 73),
                          )

                          if (value.length === 0) {
                            resetProductInformations();
                          }
                        }}
                        onChange={(value) => onChangeReference(value)}
                        onBlur={(event) => checkReferenceIsValid(event.target.value)}
                      />
                      {productIsAvailable && comingSoon &&
                        formik.values.refcatalogue !== '' && (
                          <div className={'mb-2'}>
                            <small className={'badge badge-danger'}>{`Produit disponible à partir du ${format(
                              formik.values.delivery.dateStart,
                              'dd/MM',
                            )}`}</small>
                          </div>
                        )}
                      {!productIsAvailable &&
                        !formik.values.scompo.hasAvailableDates && (
                          <div className={'mb-2'}>
                            <small className={'badge badge-danger'}>
                              Produit actuellement indisponible
                            </small>
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                {
                  <Image
                    onError={(e) => {
                      e.target.src =
                        'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                    }}
                    src={
                      formik.values.scompo.id !== ''
                        ? `https://cdn.florajet.com/produits/300/${formik.values.scompo.id}.jpg`
                        : 'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                    }
                    onClick={() => setModalImage(true)}
                    alt={formik.values.scompo.reference}
                    className="img-fluid mb-1 mx-auto d-block"
                  />
                }
                <small className={'d-flex justify-content-center'}>
                  {formik.values.scompo.reference}
                </small>
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  controlId={'scompo.category'}
                  label={'Choix floral'}
                  labelRequired={true}
                  name={'scompo.category'}
                  value={formik.values.scompo.category}
                  options={
                    (!isLoadingFloralChoice &&
                      floralChoices.map((item, index) => (
                        <option key={index} value={item.chxflCode}>
                          {item.chxflDesc}
                        </option>
                      ))) ||
                    []
                  }
                  onChange={(event) => {
                    formik.setFieldValue('scompo.category', event.target.value)
                    formik.setFieldValue(
                      'scompo.categoryLabel',
                      event.target.selectedOptions[0].text,
                    )
                  }}
                  error={
                    getIn(formik.touched, 'scompo.category') &&
                    getIn(formik.errors, 'scompo.category')
                  }
                />
              </Col>
              <Col>
                <Select
                  controlId={'occasion'}
                  label={'Occasion'}
                  labelRequired={true}
                  name={'occasion'}
                  value={formik.values.occasion}
                  options={
                    (!isLoadingOccasions &&
                      occasions.map((item, index) => (
                        <option key={index} value={item.idoccaz}>
                          {item.liboccaz}
                        </option>
                      ))) ||
                    []
                  }
                  onChange={(event) => {
                    formik.setFieldValue('occasion', event.target.value)
                    formik.setFieldValue(
                      'occasionLabel',
                      event.target.selectedOptions[0].text,
                    )
                    formik.setFieldValue(
                      'receiver.isMourningOccasion',
                      event.target.value === '5',
                    )

                    if (event.target.value !== '5') {
                      formik.setFieldValue('ruban', '')
                      formik.setFieldValue('hasRibbon', false)
                      formik.setFieldValue('delivery.hour', '')
                      formik.setFieldValue('delivery.minutes', '')
                    }
                  }}
                  error={
                    getIn(formik.touched, 'occasion') &&
                    getIn(formik.errors, 'occasion')
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  id={'scompo.description'}
                  name={'scompo.description'}
                  label={'Description'}
                  labelRequired={true}
                  as={'textarea'}
                  rows={5}
                  value={formik.values.scompo.description}
                  onChange={formik.handleChange}
                  error={
                    getIn(formik.touched, 'scompo.description') &&
                    getIn(formik.errors, 'scompo.description')
                  }
                />
                <Form.Text>
                  <FontAwesomeIcon
                    icon={'info-circle'}
                    size={'lg'}
                    color={'var(--primary)'}
                  />
                  <span className="ff-bold"> Information : </span>
                  Sans utiliser le champ
                  <span className="ff-bold"> Référence </span>, vous pouvez
                  indiquer plusieurs références ou un choix libre dans ce champ
                  <span className="ff-bold"> Description</span>.
                </Form.Text>
              </Col>
            </Row>
          </>
        }
      />

      <ModalCustom
        show={isShowModalReference}
        id={'modal-reference'}
        onHide={() => setIsShowModalReference(false)}
        bodyClassname={'p-3'}
        content={
          <>
            <AlertCustom
              classname="text-left"
              variant="warning"
              heading={false}
              headingTitle="Information"
              headingIconName="info-circle"
              bodyContent={'Merci de sélectionner un produit dans la liste.'}
              transition={true}
            />
          </>
        }
        backdrop={'static'}
        footer={true}
        buttonsFooter={
          [
            {
              text: 'Fermer',
              func: () => setIsShowModalReference(false),
            },
          ]
        }
      />
    </>
  )
}

ProductForm.propTypes = {
  formik: PropTypes.object.isRequired,
  colXs: PropTypes.number,
  colSm: PropTypes.number,
}
