import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DatePicker, {registerLocale} from "react-datepicker";
import React, {useContext} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fr from "date-fns/locale/fr";
import {format, formatISO} from "date-fns";
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import AuthContext from "../../../contexts/AuthContext";

registerLocale('fr', fr);

export const ExecutionsFilters = () => {
    const setType = useStoreExecutions(state => state.setType);
    const setSearchText = useStoreExecutions(state => state.setSearchText);
    const searchText = useStoreExecutions(state => state.searchText);
    const setDateStart = useStoreExecutions(state => state.setDateStart);
    const dateStart = useStoreExecutions(state => state.dateStart);
    const setDateEnd = useStoreExecutions(state => state.setDateEnd);
    const dateEnd = useStoreExecutions(state => state.dateEnd);
    const resetType = useStoreExecutions(state => state.resetType);

    const handleSearch = (event) => {
        event.preventDefault()
        setType('search');
    }
    
    const handleCancel = () => {
        resetType();
    }

    return (
        <>
            <Form onSubmit={handleSearch} className={'mb-3'}>
                <Row>
                    <Col md={5}>
                        <Form.Group as={Col} controlId={'locality'}>
                            <Form.Label>Localité ou numéro de commande :</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={'Localité ou numéro de commande'}
                                name={'searchExecutions'}
                                value={searchText}
                                onChange={(event) => setSearchText(event.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group as={Col}>
                            <Form.Label htmlFor={'start'}>Date de début :</Form.Label>
                            <DatePicker
                                name={"start"}
                                id={"start"}
                                dateFormat={'dd/MM/yyyy'}
                                className={'form-control'}
                                placeholderText={'--/--/----'}
                                openToDate={new Date()}
                                locale={'fr'}
                                showLeadingZeros={true}
                                calendarIcon={null}
                                onChange={setDateStart}
                                selected={dateStart}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group as={Col}>
                            <Form.Label htmlFor={'end'}>Date de fin :</Form.Label>
                            <DatePicker
                                name={"end"}
                                id={"end"}
                                dateFormat={'dd/MM/yyyy'}
                                className={'form-control'}
                                placeholderText={'--/--/----'}
                                openToDate={dateStart ?? new Date()}
                                minDate={dateStart ?? false}
                                locale={'fr'}
                                showLeadingZeros={true}
                                calendarIcon={null}
                                onChange={setDateEnd}
                                selected={dateEnd}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3} className={'d-flex  align-self-end'}>
                        <div className={'d-flex flex-row'}>
                            <Button
                                type="submit"
                                className="d-flex btn-square align-items-center mr-2">
                                <FontAwesomeIcon icon="search" color="white" />
                            </Button>
                            <Button
                                className="btn-red btn-square d-flex align-items-center"
                                onClick={handleCancel}>
                                <FontAwesomeIcon
                                    icon="times"
                                    color="white"
                                    className="margin-icon"
                                />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
}