import {useContext, useEffect, useState} from "react";
import AuthContext from "../contexts/AuthContext";
import useFetch from "./useFetch";
import ExecutionsAPI from "../services/ExecutionsAPI";
import {Store} from "../providers/MenuProvider";
import Sonnerie from '../assets/audio/sonnerie_v2.mp3';
import {useMercureSubscriber} from "./useMercureSubscriber";
import {useStoreExecutions} from "../store/useStoreExecutions";

export function useNotifications() {
    const {user} = useContext(AuthContext);
    const {state, dispatch} = useContext(Store);
    const {result: newExecutions} = useMercureSubscriber(['/orders/news']);
    const [newNotifications, setNewNotifications] = useState();
    const setExecutionsInPendingCount = useStoreExecutions((state) => state.setExecutionsInPendingCount);
    const executionsInPendingCount = useStoreExecutions((state) => state.executionsInPendingCount);
    const activeRing = useStoreExecutions((state) => state.activeRing);
    const setActiveRing = useStoreExecutions((state) => state.setActiveRing);

    const [{data: notifications}, fetchData] = useFetch(
        ExecutionsAPI.getNotifications(user.CodeFl),
    );

    // // Gestion du changement d'url
    // useEffect(() => {
    //         if (state.reload) {
    //             fetchData(ExecutionsAPI.getNotifications(user.CodeFl));
    //         }
    //     }, [user.CodeFl]
    // );

    // Gestion de la sonnerie si notifications
    useEffect(() => {
        if (notifications && notifications.count > 0) {
            setExecutionsInPendingCount(notifications.count, user);
        }
    }, [notifications]);

    // On fait resonner
    useEffect(() => {
        if (newExecutions.data && executionsInPendingCount !== newExecutions.data.count) {
            setExecutionsInPendingCount(newExecutions.data.count, user);
        }
    }, [newExecutions]);

    useEffect(() => {
        if (executionsInPendingCount > 0 && activeRing) {
            handlePlay();
        }
    }, [executionsInPendingCount]);


    // Gestion du changement de magasin
    useEffect(() => {
            dispatch({
                type: 'RELOAD_RING',
                reloadRing: true,
            });
        }, [user.CodeFl]
    );

    // Gestion du lancement de la sonnerie
    function handlePlay() {
        setActiveRing(false);
        const iframeRing = document.createElement('iframe');
        iframeRing.id = 'background-audio';
        iframeRing.src = Sonnerie;
        iframeRing.allow = 'autoplay';
        document.body.appendChild(iframeRing);
        setInterval(() => {
            iframeRing.remove();
            setActiveRing(true);
        }, 4000);
    }

    return {newNotifications, handlePlay};
}